// This component will provide a table of signups (the array of which are passed in as the v-model), and provide some additional information as well as moderation options
// NOTE: We're not fetching the signups here as that would give us less flexibility/control + then we'd need to implement some fancy way of returning the data to the user; we can always change this down the line, but this should at least give us most of the styling/markup in a seperate file + avoid most of the bulk in the main file.
<template>
  <div class="w-full">
    <t-card>
      <template v-slot:header>
        <h4 class="uppercase text-green-500">
          <span class="ml-3">Branding</span>
        </h4>
        <h5 class="uppercase text-orange-500">
          <span class="ml-3">WARNING: If you change any of the images without saving the settings first, your settings may be lost</span>
        </h5>
      </template>

      <div class="flex mb-4">
        <!-- The tournament's logo / main image -->
        <div class="w-1/4 rounded overflow-hidden shadow-lg">
          <img class="object-contain w-full" :src="value.image" />
          <div class="px-6 py-4">
            <ChangeImageWidget v-on:refresh="onImageUpdated" :url="generateChangeImageURL" />
          </div>
        </div>
        
        <!-- The tournament's main banner image -->
        <div class="w-3/4 rounded overflow-hidden shadow-lg">
          <img class="object-contain w-full h-64" :src="value.mainbanner_image" />
          <div class="px-6 py-4">
            <ChangeImageWidget v-on:refresh="onImageUpdated" :url="generateChangeMainBannerImageURL" :preview_height="256" :preview_width="1024" />
          </div>
        </div>
      </div>
    </t-card>
    </div>
</template>

<script>
import ChangeImageWidget from '@/components/ChangeImageWidget.vue';

export default {
  name: 'TournamentBrandingManagement',
  props: {
    value: {
      type: Object
    }
  },
  components: {
    ChangeImageWidget
  },
  methods: {
    onImageUpdated()
    {
      this.$emit('refresh');
    }
  },
  computed: {
    generateChangeImageURL()
    {
      return "tournaments/manage/"+this.value.id+"/upload/image"
    },
    generateChangeMainBannerImageURL()
    {
      return "tournaments/manage/"+this.value.id+"/upload/mainbanner_image"
    }
  }
}
</script>
