// Central helper function to abstract axios / to make things such as changing the URL or other configuration easier.
///import Vue from 'vue'

var axios = require('axios');

// Set up a custom base URL (kept as an exported variable to allow access from regular axios clients too for the login & refresh-related stuff)
// NOTE: Only prefix the full API site URL for non-development builds (else the proxy configuration is a pain or potentially even impossible to set up (due to HTTP vs HTTPS?))
const BASE_URL = process.env.NODE_ENV === 'development' ? "/api/v0" : "https://tournament.theproleagues.com/api/v0"
const apiClient = axios.create({
    baseURL: BASE_URL
});

// NOTE: Should be passed to tpl-vue-auth-helpers initialization to make sure the token stuff gets hooked up

export {apiClient}
