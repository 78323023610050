// TODO: Someone with some more artistic skills should probably create something more like: https://tailwindcomponents.com/component/multistep-form-with-tailwindcss-and-alpinejs
<template>
  <div class="home flex flex-col items-center">
    <form-wizard class="w-1/2" @on-complete="handleCreation" @on-error="handleErrorMessage" shape="tab">
      <template v-slot:title>
          <span class="text-xl">Create New Tournament</span>
      </template>

      <template v-slot:prev>
          <t-button>Back</t-button>
      </template>

      <template v-slot:next>
          <t-button>Next</t-button>
      </template>

      <div v-if="formErrorMsg">
        <span class="text-orange-600">{{formErrorMsg}}</span>
        <br />
      </div>
      <tab-content title="Basic Information" :before-change="validateBasicInfo">
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">
          Name
        </label>
        <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          v-model="tournament.name"
          name="name"
          id="name"
          placeholder="Tournament Name"
        />

        <br />
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="prize">
            Prize
          </label>
          <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            v-model="tournament.prize"
            name="prize"
            id="prize"
            placeholder="Tournament Prize"
          />
      </tab-content>
      <tab-content title="Dates" :before-change="validateDates">
        <!-- TODO: We may want to implement some form of validation that these dates are greater than the previous? But that's an advanced feature not really relevant for the MVP -->
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="signup_deadline">
          Signup Deadline
        </label>
        <TimeStampPicker id="signup_deadline" v-model="tournament.signup_deadline" />  
        
        <br />
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="start_time">
          Tournament Start
        </label>
        <TimeStampPicker id="start_time" v-model="tournament.start_time" />
        
        <br />
        <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="end_time">
          Tournament End
        </label>
        <TimeStampPicker id="end_time" v-model="tournament.end_time" />
      </tab-content>
      <tab-content title="Review">
        You'll be creating '{{ tournament.name }}', which will run between {{ tournament.start_time | dumb_format_date }} and {{ tournament.end_time | dumb_format_date }}. Users can sign up until {{ tournament.signup_deadline | dumb_format_date }} for a chance to win '{{ tournament.prize }}''.
        <br />
        <i>Please note that your tournament will be created as a DRAFT, and will not be published until you press the <strong>publish</strong> button on the edit page.</i>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
// Ensure we can perform an API login (kept in the authGuard file to keep all auth-related code in one place)
import {FormWizard, TabContent} from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

import TimeStampPicker from '@/components/TimeStampPicker.vue';

import moment from 'moment';

import { store } from '@/store/index';
import {apiClient} from "../apiClient";

export default {
  name: 'CreateTournament',
  data() {
    return {
      // The tournament data key will store all of the tournament configuration the user configures
      // NOTE: All times are local times, and will need to be adjusted for by timezone!
      tournament: {name: "", prize: "", signup_deadline: null, start_time: null, end_time: null},
      formErrorMsg: ""
    }
  },
  components: {
    FormWizard,
    TabContent,
    TimeStampPicker
  },
  methods: {
    handleCreation: async function ()
    {
      // Assume form steps performed all of the validation, and that the server will catch any fatal/dangerous ones -> no need to do validation in this function
      
      var response = await apiClient.post('tournaments/manage/create', {
        // Core information (required for the API to create the tournament)
        name: this.tournament.name,

        // NOTE: The backend expects the timestamp to be a "formatted" string/not a unix timestamp -> we'll need to add some code to handle this
        signup_deadline: moment(this.tournament.signup_deadline).format(),
        start_time: moment(this.tournament.start_time).format(),
        end_time: moment(this.tournament.end_time).format(),

        // Optional things that we want to set up on the intial runs (-> are requiring the user to enter)
        prize: this.tournament.prize,

        // NOTE: Images can only be uploaded after the tournament has been created!


      }).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Creation error: "+error);
          return false;
      })

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve the create a new tournament; please try again later");
        return;
      }
      
      // Successfully created tournament!
      this.$router.push({name: "TournamentDetail", params: { tournament_id: response.data.id }});
    },
    validateBasicInfo() {
      return new Promise((resolve, reject) => {
        if (this.tournament.name === "")
        {
          reject('You must enter a name for this tournament.');
        }
        else if (this.tournament.prize === "")
        {
          reject('You must enter a prize for this tournament.');
        }
        else
        {
          // All checks pass - succeed the reuqest
          resolve(true);
        }
      })
    },
    validateDates() {
      return new Promise((resolve, reject) => {
        // TODO: Should we run these through moment as well?
        if (this.tournament.signup_deadline == null)
        {
          reject('You must provide a signup deadline for this tournament.');
        }
        else if (this.tournament.start_time == null)
        {
          reject('You must provide a starting date for this tournament.');
        }
        else if (this.tournament.end_time == null)
        {
          reject('You must provide an ending date for this tournament.');
        }
        // TODO: Implement parsing + checking if all dates are after eachother! Not doing this for the MVP, but should deffo be added at some point
        else
        {
          // All checks pass - succeed the reuqest
          resolve(true);
        }
      })
    },
    handleErrorMessage: function(errorMsg){
      this.formErrorMsg = errorMsg
    }
  },
  filters:
  {
    dumb_format_date: function(date)
    {
      // Simple formatting function that will format the date including timezone without any TZ conversion or advanced stuff
      return moment(date).tz(store.getters.timezone).format('LL HH:mm z')
    }
  }
}
</script>
