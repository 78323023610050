<!-- This component will provide a form with settings for the tournament.
I have no clue if this is the way to do things, especially with the saving function here, but this should allow us to implement form validation (including server-based form validation) in the future.
It also helps keep all of the editing/saving stuff in one place, as that way, if we do ever need to implement more advanced mapping/parsing/stuff like that, we can just do it in here without needing to update it in potentially multiple places if we have config editing in different places.<style scoped>
But as always: I am a n00b at this Vue stuff, so I may very well be wrong; this is/was mainly just a project for learning more/experiemnting with things, so that's what I am doing. -->

<template>
  <div class="w-full">
    <t-card>
      <template v-slot:header>
        <h4 class="uppercase text-green-500">
          <span class="ml-3">Settings</span>
        </h4>
      </template>
      <div class="flex justify-center">
        <form class="w-full max-w-lg" @submit.prevent="saveTournamentConfiguration">
          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">
                Name
              </label>
              <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                v-model="value.name"
                name="name"
                id="name"
                placeholder="Tournament Name"
              />
            </div>
            <div class="w-full md:w-1/2 px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="region">
                Region
              </label>
              <t-select
                v-model="value.tournament_region"
                name="region"
                id="region"
                placeholder="Server Location"
                :options="regions"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bracket_type">
                Bracket Type
              </label>
              <t-select
                v-model="value.bracket_type"
                name="bracket_type"
                id="bracket_type"
                :options="[
                {
                  value: 'single_elim',
                  label: 'Single Elimination'
                },
                {
                  value: 'dobule_elim',
                  label: 'Double Elimination'
                }]"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="prize">
                Prize
              </label>
              <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                v-model="value.prize"
                name="prize"
                id="prize"
                placeholder="Tournament Prize"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full px-3">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="description">
                Description
              </label>
              <t-textarea class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                v-model="value.description"
                name="description"
                id="description"
                placeholder="Tournament description"
                :rows="5"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="min_team_size">
                Minimum Team Size
              </label>
              <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                v-model="value.minimum_team_size"
                name="min_team_size"
                id="min_team_size"
                placeholder="Min. Team Size"
              />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="max_num_signups">
                Signup Limit
              </label>
              <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="number"
                v-model="value.max_num_signups"
                name="max_num_signups"
                id="max_num_signups"
                placeholder="0 for No Limit"
              />
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="signup_deadline">
                Signup Deadline
              </label>
              <TimeStampPicker id="signup_deadline" v-model="value.signup_deadline" />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="match_spacing">
                Match Spacing
              </label>
              <date-picker v-model="value.match_scheduler_spacing" valueType="format" type="time" id="match_spacing"></date-picker>
            </div>
          </div>

          <div class="flex flex-wrap -mx-3 mb-6">
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="start_time">
                Tournament Start
              </label>
              <TimeStampPicker id="start_time" v-model="value.start_time" />
            </div>
            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="end_time">
                Tournament End
              </label>

              <TimeStampPicker id="end_time" v-model="value.end_time" />
            </div>
          </div>

          <t-button variant="primary" type="submit">Save</t-button>
        </form>
      </div>
    </t-card>
    </div>
</template>

<script>
import moment from 'moment';
import {apiClient} from "../apiClient";
import TimeStampPicker from '@/components/TimeStampPicker.vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'TournamentConfigurationManagement',
  data() {
    return {
      // Used for displaying more information about a team that has signed up
      selected_signup: null,

      // Used for the dropdown with all possible regions the tournament can use
      regions: []
    }
  },
  props: {
    // The tournament configuration
    value: {
      type: Object
    }
  },
  components: {
    TimeStampPicker,
    DatePicker,
  },
  methods: {
    async saveTournamentConfiguration()
    {
      // Copy over the tournament configuration so we only have to worry about parsing the date / all other attributes are done by default and it won't need changing if we add anything to the API
      // NOTE: We are making a copy to avoid mutating/breaking the original dict; see the "For JSON" comment to the original question here: https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object
      var request = JSON.parse(JSON.stringify(this.value));
      request.start_time = moment(this.value.start_time).format();
      request.end_time = moment(this.value.end_time).format();
      request.signup_deadline = moment(this.value.signup_deadline).format();
      
      // Execute the PUT HTTP request that will update the tournament
      var response = await apiClient.put('tournaments/manage/'+this.value.id, request).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to save tournament! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to save tournament! Please try again later");
        return;
      }

      // Reload the tournament configuration; this shouldn't change much, as the user's changes were saved but could avoid things getting out of sync
      // NOTE: We're requesting the parent to do this as we don't actually load the data ourselves (that way 2 way binding can do its thing/we can easily reference properties in the parent, but by saving in here we can run all kinds of validation (including server-based validation), so yeah...)
      this.$emit('refresh')

      // Show a success message
      // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
      alert("Successfully saved tournament!");
    },
    async refreshRegionOptions()
    {
      var response = await apiClient.get('tournaments/manage/regions').catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to obtain region list! Error: "+error);
          return false;
      });

      // Was the request successful?
      if (!response || !response.data.success)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to obtain list of regions tournament! Please try again later");
        return;
      }

      // Update the data list of regions
      this.regions = response.data.regions;
    }
  },
  created()
  {
    // Obtain the list of available regions so they can be used in the dropdown
    this.refreshRegionOptions()
  }
}
</script>
