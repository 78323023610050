import Vue from 'vue'
import Vuex from 'vuex'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

Vue.use(Vuex)
Vue.use(VueMoment, {
  moment,
})

export const store = new Vuex.Store({
  state: {
    // Get the timezone from the local storage, or grab the default if it has not yet been set
    // NOTE: This is kind of a bodge, but should work for now without tying us in to a system like localStorage or an API (-> we can easily change the code to load the local vuex state later)
    timezone: localStorage.timezone ? localStorage.timezone : moment.tz.guess() // Set up a timezone with a default timezone of whatever moment thinks the user is running
  },
  mutations: {
    changeTimezone(state, new_timezone) {
      // Update the vuex state to reflect this new timezone
      state.timezone = new_timezone
      // Save it to the local storage too so the change persists
      localStorage.timezone = new_timezone;
    }
  },
  getters: {
    timezone: state => state.timezone
  },
  actions: {
  },
  modules: {
  }
})

// Adding this keeps the "default" this.$store happy, but also allows us to easily import { store } from this file (hence the const store= above)
export default store;