<template>
  <div class="home flex flex-col items-center" v-if="tournament_configuration">

    <t-modal v-if="tournament_configuration" ref="deleteTournamentModal"
    wrapper-class="bg-red-100 border-red-400 text-red-700 rounded shadow-xl flex flex-col"
    overlay-class="z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-red-900 opacity-75"
    body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
    footerClass="bg-red-400 p-3 flex justify-between">
      <!-- This div allows for some protection against selected_signup being null, while still allowing us to use modal.show(). We may potentially just want to get rid of that show code, and always show it with a v-if for selected_tournament, but this makes more sense/will likely be clearer while programming -->
      <!-- UPDATE: Now switched to avoid weird alignment issues -->
        <h1>Are you sure you'd like to remove '{{ tournament_configuration.name }}'?</h1>
        <h2>This action is irreversable, and you will not be able to restore your tournament</h2>

      <template v-slot:footer>
          <t-button @click="$refs.deleteTournamentModal.hide()" variant="primary">
            Cancel
          </t-button>	
          <t-button @click="deleteTournament()" variant="danger">
            <!-- TODO -->
            Yes, Delete
          </t-button>
        </template>
    </t-modal>

    <t-modal v-if="tournament_configuration" ref="publishTournamentModal"
    wrapper-class="bg-green-100 border-green-400 text-gray-700 rounded shadow-xl flex flex-col"
    overlay-class="z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-green-900 opacity-75"
    body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
    footerClass="bg-green-400 p-3 flex justify-between">
      <!-- This div allows for some protection against selected_signup being null, while still allowing us to use modal.show(). We may potentially just want to get rid of that show code, and always show it with a v-if for selected_tournament, but this makes more sense/will likely be clearer while programming -->
      <!-- UPDATE: Now switched to avoid weird alignment issues -->
        <h1>Are you sure you'd like to publish '{{ tournament_configuration.name }}'?</h1>
        <h2>This action will publish the tournament on the site and in-game, and enable users to sign up for it.</h2>
        <i>NOTE: This will save any changes you may have made in the "settings" section</i>

      <template v-slot:footer>
          <t-button @click="$refs.publishTournamentModal.hide()" variant="primary">
            Cancel
          </t-button>	
          <t-button @click="changePublishedState(true)" variant="success">
            Yes, Publish
          </t-button>
        </template>
    </t-modal>

    <t-modal v-if="tournament_configuration" ref="unpublishTournamentModal"
    wrapper-class="bg-yellow-100 border-yellow-400 text-gray-700 rounded shadow-xl flex flex-col"
    overlay-class="z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-yellow-900 opacity-75"
    body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
    footerClass="bg-yellow-400 p-3 flex justify-between">
      <!-- This div allows for some protection against selected_signup being null, while still allowing us to use modal.show(). We may potentially just want to get rid of that show code, and always show it with a v-if for selected_tournament, but this makes more sense/will likely be clearer while programming -->
      <!-- UPDATE: Now switched to avoid weird alignment issues -->
        <h1>Are you sure you'd like to unpublish '{{ tournament_configuration.name }}'?</h1>
        <h2>This action will mark the tournament as a draft, hide it on the site and in-game and stop further signups and matches from being played.</h2>
        <i>NOTE: This will save any changes you may have made in the "settings" section</i>
      <template v-slot:footer>
          <t-button @click="$refs.unpublishTournamentModal.hide()" variant="primary">
            Cancel
          </t-button>	
          <t-button @click="changePublishedState(false)" variant="warning">
            Yes, Unpublish
          </t-button>
        </template>
    </t-modal>

    <img class="w-auto h-64" :src="tournament_configuration.mainbanner_image" alt="Tournament Banner" />

    <h1>Tournament Configuration</h1>
    <h3>{{ tournament_configuration.name }}</h3>
    <h5>Running from {{ tournament_configuration.start_time | formatTournamentTimestamp}} till {{ tournament_configuration.end_time | formatTournamentTimestamp}}</h5>
    <h5>signups are <span class="text-green-500" v-if="isInFuture(tournament_configuration.signup_deadline)">open till {{ tournament_configuration.signup_deadline | formatTournamentTimestamp}}</span><span class="text-orange-500" v-else>Closed</span></h5>

    <br />
    <TournamentConfigurationManagement v-model="tournament_configuration" v-on:refresh="refreshTournament" ref="tournament_configuration_management" />
    <br />
    <TournamentBrandingManagement v-model="tournament_configuration" v-on:refresh="refreshTournament" />
    <br />
    <TournamentSignupManagement v-model="tournament_signups" v-on:refresh="refreshSignups" :tournament_id="tournament_configuration.id" />
    <br />
    <TournamentMatchManagement v-model="tournament_matches" v-on:refresh="refreshMatches" :tournament_id="tournament_configuration.id" />

    <br />
    <t-button size="sm" variant="warning" @click="$refs.unpublishTournamentModal.show()" v-if="tournament_configuration.publish_status=='published'">
      Unpublish Tournament
    </t-button>
    <t-button size="sm" variant="success" @click="$refs.publishTournamentModal.show()" v-else>
      <!-- Draft is currently the only other state -->
      Publish Tournament
    </t-button>
    <br />
    
    <!-- <br />
    <div class="container">
      <t-button size="sm" variant="success" @click="$refs.deleteTournamentModal.show()">
        Publish Tournament
      </t-button>
      <t-button size="sm" variant="success" @click="$refs.deleteTournamentModal.show()">
        Publish Tournament
    </t-button>
    </div>
    <br /> -->

    <t-button size="sm" variant="danger" @click="$refs.deleteTournamentModal.show()">
      Delete Tournament
    </t-button>
  </div>
</template>

<script>
import {apiClient} from "../apiClient";
import moment from 'moment';
import { store } from '@/store/index';

import TournamentSignupManagement from '@/components/TournamentSignupManagement.vue'
import TournamentConfigurationManagement from '@/components/TournamentConfigurationManagement.vue';
import TournamentMatchManagement from '@/components/TournamentMatchManagement.vue';
import TournamentBrandingManagement from '@/components/TournamentBrandingManagement.vue';

export default {
  name: 'TournamentDetail',
  data() {
    return {
      tournament_configuration: null,
      tournament_signups: [],
      // Dict and not an array because they're placed under round keys
      // UPDATE: No, we're inserting the user-friendly round number (that was the key) in there now to make displaying stuff easier / we shouldn't ever need to go back to this original form anyway
      tournament_matches: [],
    }
  },
  components: {
    TournamentConfigurationManagement,
    TournamentSignupManagement,
    TournamentMatchManagement,
    TournamentBrandingManagement
  },
  async mounted()
  {
    // Attempt to fetch the tournament
    await this.refreshTournament();
    this.refreshSignups();
    this.refreshMatches();
  },
  methods: {
    refreshTournament: async function ()
    {
      // TODO: This appears to have some form of side effect that causes the undefined one to be called too, yet that makes no sense...especially since we're passing in the route as a variable + are logging, yet that is never undefined?
      var route = 'tournaments/manage/'+this.$route.params.tournament_id;
      console.log(route);
      var response = await apiClient.get(route).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to find tournament! Error: "+error);
          return false;
      })

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, and redirect them back to the page they were previously on.
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve the that tournament; please try again later");
        this.$router.go(-1);
        return;
      }

      // Copy over the tournament so we only have to worry about parsing the date / all other attributes are done by default and it won't need changing if we add anything to the API
      this.tournament_configuration = response.data;
      this.tournament_configuration.start_time = Date.parse(this.tournament_configuration['start_time']);
      this.tournament_configuration.end_time = Date.parse(this.tournament_configuration['end_time']);
      this.tournament_configuration.signup_deadline = Date.parse(this.tournament_configuration['signup_deadline']);
    },
    refreshSignups: async function ()
    {
      var route = 'tournaments/manage/'+this.$route.params.tournament_id + "/signups";
      var response = await apiClient.get(route).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to find tournament! Error: "+error);
          return false;
      })

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as this function being called means the basic data did load
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve the tournament's signups; please try again later");
        return;
      }

      // Store the signups in the global "state" so we can access them from the UI bindings
      this.tournament_signups = response.data;
    },
    refreshMatches: async function ()
    {
      var route = 'tournaments/manage/'+this.$route.params.tournament_id + "/matches";
      var response = await apiClient.get(route).catch(error => {
          // Did we error out because no bracket had been generated yet?
          if (error.response.data.reason == "Bracket has not yet been generated")
          {
            console.log("NO_BRACKET");
            
            // Set the tournament_matches variable to an empty array to signify this. 
            // I also consdired none, but then we'd have a different type
            // + other than during load, there should always be at least one match if there is a tournament -> there isn't a collision with an expected use care
            this.tournament_matches = [];

            // Return null so we can view this as an "already handled" response opposed to the false that prints an error
            return false;
          }
          else
          {

            // Catch all errors and fail the request if one is detected
            console.log("Unable to get tournament matches! Error: "+error);
            
            // Detected an error! Alert the user of this, but stay on the page as this function being called means the basic data did load
            // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
            alert("Unable to retrieve the tournament's matches; please try again later");
          }
          return false;
      })

      // Were any error detected? If so, return early/don't do any further processing
      if (!response || !response.data.success)
      {
        return;
      }

      // Store the matches in the global "state" so we can access them from the UI bindings
      // But before  we do this, we need to "inject" the user-friendly round number (not the ID as that makes no sense to regular people) in each match
      var response_rounds = response.data.rounds;
      var parsed_matches = []
      for (var round_key in response_rounds)
      {
        for (const match of response_rounds[round_key])
        {
          var parsed_match = match;
          parsed_match.round_num = parseInt(round_key) + 1; // Add one to avoid 0-indexing (not all that user-friendly)
          parsed_matches.push(parsed_match);
        }
      }
      this.tournament_matches = parsed_matches;
    },
    isInFuture(timestamp)
    {
      // moment() gives us the current timestamp, and the passed in timestamp will be in the future if the current time is before the passed in stamp
      return moment().isBefore(timestamp);
    },
    async deleteTournament()
    {
      // Execute the DELETE HTTP request that will remove the tournament
      var response = await apiClient.delete('tournaments/manage/'+this.tournament_configuration.id).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to remove tournament! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to remove tournament! Please try again later");
        return;
      }

      // Signup deleted; close the modal, alert the user and redirect back to the home page
      // TODO: Repalce this alert with a friendlier system
      this.$refs.deleteTournamentModal.hide();
      this.$router.push("/");
    },
    async changePublishedState(bIsPublished)
    {
      // Currently only draft/published statuses are supported; this is done using the configuration struct, but may want to become its own endpoint in the future.
      this.tournament_configuration.publish_status = bIsPublished ? "published" : "draft";

      // Use the component's save function to ensure all validation and conversion is done.
      // NOTE: This is kind of a hack anyway as it has the sideeffect of saving (only the "core") settings, but I guess that that'll just have to do for this initial version
      // NOTE2: Also assumes that it saves successfully; we don't have any custom error handling code here
      await this.$refs.tournament_configuration_management.saveTournamentConfiguration();

      // State updated; hide both modals (hiding an already hidden modal shouldn't change anything)
      this.$refs.publishTournamentModal.hide();
      this.$refs.unpublishTournamentModal.hide();
    },
  },
  filters: {
    formatTournamentTimestamp(value)
    {
      // NOTE: We're using a filter here and accessing the store so we can recompute the value every time the timezone is changed without adding any complex watchers/calling functions again: it's simple; if the vuex state changes, this function will be called again.  
      return moment(value).tz(store.getters.timezone).format('DD/MM/YYYY HH:mm z')
    }
  }
}
</script>
