// This component contains the code required for presenting the user with a dialog to change the image + actually
// PUT-ing that image to the web server.
<template>
  <div>
    <!-- Modal for actually changing the image -->
    <t-modal ref="PickImageModal">
        <template v-slot:header>
          <h4 class="uppercase text-green-500">
            <span class="ml-3">Change Image</span>
          </h4>
        </template>
        <form @submit.prevent="performUpload">
          <div class="w-full"  v-if="previewImage">
            <!-- Image cropper widget that allows the user to select an image with the right aspect ratio based on what they uploaded -->
            <vue-cropper
                ref="cropper"
                :src="previewImage"
                :aspect-ratio="preview_width / preview_height"
                alt="Source Image"
              />
              <br /><br />
          </div>
          <label>
            <!-- <img :src="previewImage" :width="preview_width" :height="preview_height" /> -->
            <input ref="selectedImage" type="file" id="img" accept="image/*" @input="pickFile" />
          </label>
          <t-button variant="primary" type="submit">Upload &amp; Save</t-button>
        </form>
      </t-modal>

      <!-- Button to allow the user to change the image -->
      <t-button @click="$refs.PickImageModal.show()" variant="primary">Change Image</t-button>	
  </div>
</template>

<script>
import {apiClient} from "../apiClient";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'ChangeImageWidget',
  data() {
    return {
      // Loaded BASE64 representation of the image for preview reasons
      previewImage: null,

      // The form image field v-model
      formImage: null
    }
  },
  components: {
    VueCropper
  },
  props: {
    // The URL to which the image should be uploaded
    url: {
      type: String,
      default: null
    },

    // Preview sizes (won't actually do any cropping for now; should be seen as a ratio rather than number of pixels if the numbers are large -> card will "constrain" size but should keep aspect ratio)
    // NOTE: These don't appear to work after the image is uploaded (will set the width to 100% and height to auto), but it should do the trick for now / we aren't doing cropping anyway
    preview_width: {
      type: Number,
      default: 256
    },
    preview_height: {
      type: Number,
      default: 256
    },
  },
  methods: {
    async performUpload()
    {
      // Ensure an image was selected
      if (!this.formImage)
      {
        console.log("No image uploaded");
        // TODO: Make this a more user-friendly popup
        alert("Please upload an image first!");
        return;
      }

      // Get the cropped data
      const canvas = this.$refs.cropper.getCroppedCanvas()
      canvas.toBlob(async (blob) => {
        // Get the image's name and ensure it has the .png extension (which is the type of blob we got by default). This avoids the generic "blob" showing up in the s3 bucket over and over
        var fileName = this.formImage.name.substr(0, this.formImage.name.lastIndexOf(".")) + ".png";

        // Now that we know an image was selected, actually upload the data & update the image (largely based on: https://serversideup.net/uploading-files-vuejs-axios/)
        let formData = new FormData();
        formData.append('image', blob, fileName);
        var response = await apiClient.put(this.url,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        )
        .catch(function(data){
          console.log(data);
          return false;
        });

        if (!response)
        {
          // Unable to upload image - log an error to the user and close the modal
          console.log('Unable to upload image!');
          // TODO: More user-friendly alert
          alert("Unable to upload image. Please reload and/or try again later");
          this.$refs.PickImageModal.hide();
          return;
        }
        // Successfully uploaded image; close the modal and emit the refresh event
        this.$emit('refresh');
        this.$refs.PickImageModal.hide();
        return;
      });
    },
    pickFile () {
        // Update the internal state to reflect this new form data (only grab the first file; used for data submission)
        this.formImage = this.$refs.selectedImage.files[0]

        // Change the preview image data by reading the file from the disk
        let input = this.$refs.selectedImage;
        let file = input.files;
        if (file && file[0]) {
          // NOTE: Setting the previewImage to null first will force the old cropper to disappear -> meaning a new one gets spawned -> the image gets properly updated.
          // This mayn't be the most elegant way to do it, but since $forceUpdate() didn't work, and this did, and as I've already spent a whole lot of time on this if it works I'll just let it go.
          this.previewImage = null;

          let reader = new FileReader
          reader.onload = e => {
            this.previewImage = e.target.result;
          }
          reader.readAsDataURL(file[0]);
        }
      }
  }
}
</script>