// This component contains a timezone-aware datetime picker that will allow the user to pick a timestamp (
//  down to the minute) in the local timezone / the timezone set up with the TimeZoneDropdown stored in the 
// Vuex state using a picker, and will take in a v-model where it will set the UTC (/non-local-timezone) timestamp.
<template>
  <date-picker 
  v-model="localTimestamp" 
  :format="formatDatePicker" 
  value-type="timestamp" 
  type="datetime" 
  :show-second="false"></date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { store } from '@/store/index';

export default {
  name: 'TimeStampPicker',
  data() {
    return {
      // The local/timepicker's timestamp; this won't be changed based on timezone
      localTimestamp: null,
      formatDatePicker: {
        stringify: (date) => {
          // NOTE: Simply suffix the timezone name to the string and worry about conversion to UTC later. This means that the handles/numbers will reflect the local time zone as well, and avoids a confusing situation where the time shows something different in the text than in the picker. This does break automatic tz conversion and isn't the most elegant solution, so we may want to revisit this later
          var result = date ? moment(date).format('LL HH:mm z') + moment(date).tz(store.getters.timezone).format('z') : null;
          return result;
        },
        parse: (value) => {
          // Parse the time ignoring the timezone (else it won't be "local" time anymore -> could cause issues); NOTE: is this actually called? Haven't seen it be called -> this is all untested
          var result = value ? moment(value, 'LL HH:mm') : null;
          return result;
        }
      },
    }
  },
  props: {
    // NOTE: This is just the passed in value; since there's a discrepency in time between this (UTC time) and the local timezone, we can't just straight up use this as the timepicker input
    value: {
      type: Number, // NOTE: We're assuming a unix timestamp/a number, not a formatter timezone; appears to be the same as moment.valueOf (by default/from the parsed API responses too) -> 
      default: null
    }
  },
  components: {
    DatePicker
  },
  methods: {
    onTimeUpdated()
    {
      // Function that gets called whenever the local time is updated; this will update the bound v-model with the appropriate UTC/timestamp value
      this.$emit('input', this.convertToUtc(this.localTimestamp).valueOf())
    },
    convertToUtc: function(localTime)
    {
      // This function will return the UTC representation of the localTime based on the timezone set up in the vuex state.

      // This deals with the timepicker selecting the timezone using the local time, but the timestamp being UTC (-> in my case, adds the two missing hours in the timestamp back)
      var format = moment(localTime).format("YYYY-MM-DDTHH:mm:ss");

      // Now that we have a string containing the date/time we want, we can interpret that timestamp as in the desired timezone and return it (the resulting timestamp is independent of tz -> if we grab valueOf() we'll get the UTC timestamp that we want, but it'll be for whatever the format string is in the selected timezone)
      return moment.tz(format, store.getters.timezone);
    },
    handleValueChanged()
    {
      // Convert the UTC timestamp to a local timestamp, and update the local time to reflect this change
      // NOTE: We need to do this conversion since timestamps are all in UTC, so we need to essentially fake the timestamp in such a way that the user's dials will also show the right tz
      var localTime = moment(moment(this.value).tz(store.getters.timezone).format("YYYY-MM-DDTHH:mm:ss")).valueOf();
      if (localTime != this.localTimestamp)
      {
        // Timestamp changed because of an external action (v-model was likely updated) -> we should update too
        this.localTimestamp = localTime;
      }
      else
      {
        // Nothing to change (likely an update by the user triggering the v-model to update) -> do nothing
      }
    }
  },
  filters:
  {
    dumb_format_date: function(date)
    {
      // Simple formatting function that will format the date including timezone without any TZ conversion or advanced stuff
      return moment(date).utc().format('LL HH:mm z') + moment.tz.zone(store.getters.timezone).abbrs[0]
    }
  },
  watch: {
    localTimestamp()
    {
      // The user changed the time; call the update function to update the v-model with the new (UTC) time
      this.onTimeUpdated();
      //this.$emit('input', value)
    },
    value()
    {
      // NOTE: This watcher will catch changes from the parent component (meaning that if the v-model value is updated, this will be called), but will also catch changes we emit ourselves; this shouldn't cause any issues tho as updates are pretty infrequent + the value shouldn't change / doing this shouldn't break stuff
      // UPDATE on that last part: we're now checking against the existing this.localTimestamp value to ensure we don't hit an unterminated recursion issue
      this.handleValueChanged();
    }
  },
  created() {
    // Watch for changes in the selected timezone; these won't cause an update to the local time (numerical) value -> won't call the existing watch, but we do need to update the UTC time as this has likely changed -> recompute
    this.storeTimezoneWatcher = store.watch(
      (state, getters) => getters.timezone,
      () => {
        this.onTimeUpdated();
      },
    );
  },
  mounted() {
    // Update the local timestamp based on what was passed in in the v-model (subsequent changes in the parent component should be caught by the watch, but this first one is on us)
    this.handleValueChanged();
  },
  beforeDestroy() {
    // Unwatch the vuex timezone state changes
    this.storeTimezoneWatcher();
  },
}
</script>
