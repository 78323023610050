<template>
  <div class="home flex flex-col items-center">
    <img class="w-1/4" alt="TPL logo" src="../assets/logo.png">

    <h1>TPL Admin Panel</h1>
    <h3>Manage your tournaments here</h3>

    <div v-if="tournaments.length > 0" class="w-full">
      <!-- Show the user their tournaments if they have any -->
      <router-link :to="{name: 'CreateTournament'}">
        <t-button size="sm" variant="secondary">Create Tournament</t-button>
      </router-link>

      <h2>Tournaments:</h2>
      <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
      <t-table
        :headers="['', 'Name', 'Start', 'End', '# Signups', 'Status', 'Actions']"
        :data="tournaments"
      >
      <template v-slot:row="props">
        <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
          <td :class="props.tdClass">
            <img class="object-contain h-24" :src="`${props.row.image}`" />
          </td>
          <td :class="props.tdClass">
            {{ props.row.name }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.start_time | formatTournamentTimestamp }}
          </td>
          <td :class="props.tdClass">
            {{ props.row.end_time | formatTournamentTimestamp }}
          </td>
          <td :class="props.tdClass">
            <!-- Color the signups green if signups are still open or orange if the signup deadline has passed -->
            <span :class="{'text-green-500': isInFuture(props.row.signup_deadline), 'text-orange-500': !isInFuture(props.row.signup_deadline) }">
            {{ props.row.num_signups }}
            </span>
          </td>
          <td :class="props.tdClass">
            <!-- Color the signups green if signups are still open or orange if the signup deadline has passed -->
            <span class="text-green-500" v-if="props.row.publish_status=='published'">
              PUBLISHED
            </span>
            <span class="text-orange-500" v-else-if="props.row.publish_status=='draft'">
            DRAFT
            </span>
            <span class="text-red-500" v-else>
            UNKNOWN
            </span>
          </td>
          <td :class="props.tdClass">
            <router-link :to="{name: 'TournamentDetail', params: { tournament_id: props.row.id }}">
              <t-button size="sm" variant="secondary">Edit</t-button>
            </router-link>
          </td>
        </tr>
      </template>
      </t-table>
    </div>
    <div v-else class="w-full">
        <!-- Show a different message if no tournaments are registered -->
        <p class="text-3xl font-bold">Looks like you haven't created any tournaments yet!
          <br />Why not create one today?
        </p>

        <router-link :to="{name: 'CreateTournament'}">
          <t-button size="sm" variant="secondary">Create Tournament</t-button>
        </router-link>
    </div>
  </div>
</template>

<script>
import {apiClient} from "../apiClient";
import moment from 'moment';
import { store } from '@/store/index'

export default {
  name: 'Home',
  data() {
    return {
      tournaments: []
    }
  },
  async beforeMount()
  {
    // Attempt to fetch the latest tournaments
    await this.refreshTournamentList();
  },
  methods: {
    refreshTournamentList: async function ()
    {
      var response = await apiClient.get('tournaments/manage/').catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Login error: "+error);
          return false;
      })

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve the latest tournaments; please try again later");
        return;
      }
      
      // Update the tournament list, but iterate over the array first so we can parse the datetime objects
      this.tournaments = [];
      for (const entry of response.data)
      {
        // Copy over the tournament so we only have to worry about parsing the date / all other attributes are done by default and it won't need changing if we add anything to the API
        var parsed_tournament = entry;
        parsed_tournament.start_time = Date.parse(entry['start_time']);
        parsed_tournament.end_time = Date.parse(entry['end_time']);
        parsed_tournament.signup_deadline = Date.parse(entry['signup_deadline']);
        // Now add it to the tournaments list so it actually shows up in the list
        this.tournaments.push(parsed_tournament);
      }
      console.log(response.data);
    },
    isInFuture(timestamp)
    {
      // moment() gives us the current timestamp, and the passed in timestamp will be in the future if the current time is before the passed in stamp
      return moment().isBefore(timestamp);
    }
  },
  filters: {
    formatTournamentTimestamp(value)
    {
      // NOTE: We're using a filter here and accessing the store so we can recompute the value every time the timezone is changed without adding any complex watchers/calling functions again: it's simple; if the vuex state changes, this function will be called again.  
      return moment(value).tz(store.getters.timezone).format('DD/MM/YYYY hh:mm z')
    }
  },
  watch: {
    // count (newCount, oldCount) {
    //   // Our fancy notification (2).
    //   console.log(`We have ${newCount} fruits now, yay!`)
    // }
  }
}
</script>
