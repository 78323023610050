import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueTailwind from 'vue-tailwind'
//import VueAxiosJwt from 'vue-axios-jwt'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'


import {apiClient} from './apiClient'
import VueAuthHelpers from '@protoball/tpl-vue-auth-helpers'


import '@/assets/css/tailwind.css'

//Vue.use(VueAxiosJwt);
Vue.use(VueTailwind);
Vue.use(VueMoment, {
  moment,
})

Vue.use(VueAuthHelpers, {router: router, axios: apiClient, client_id: process.env.VUE_APP_TPL_CLIENT_ID, authorize_url: process.env.VUE_APP_TPL_AUTHORIZE_URL, token_url: process.env.VUE_APP_TPL_TOKEN_URL, scope_string: 'tournament_management'});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
