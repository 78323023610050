<template>
  <div class="home flex flex-col items-center h-screen">
    <div class="w-full max-w-xs m-auto">
      <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert" v-if="errorMessage">
        <span class="block sm:inline">{{ errorMessage }} </span>
      </div>
      <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"  @submit.prevent="handleLogin">
        <div class="mb-4">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
            Username
          </label>
          <t-input v-model="credentials.username" class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" placeholder="Username" />
          <p class="text-red-500 text-xs italic" v-if="errorMessage && !credentials.username">Please enter your username.</p>
        </div>
        <div class="mb-6">
          <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
            Password
          </label>
          <t-input class="shadow appearance-none border border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password" v-model="credentials.password" type="password" name="password" placeholder="******************"/>
          <p class="text-red-500 text-xs italic" v-if="errorMessage && !credentials.password">Please enter your password.</p>
        </div>
        <div class="flex items-center justify-between">
          <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
            Sign In
          </button>
          <a class="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="https://tournament.theproleagues.com/auth/password_reset/">
            Forgot Password?
          </a>
        </div>
      </form>
      <p class="text-center text-gray-500 text-xs">
        &copy;2020 Studio Z3D. All rights reserved.
      </p>
    </div>
  </div>
</template>

<script>
// Ensure we can perform an API login (kept in the authGuard file to keep all auth-related code in one place)
import {
  perform_api_login,
  check_is_logged_in
 } from '../auth/authGuard'

export default {
  name: 'Login',
  data() {
    return {
      credentials: {username: "", password: ""},
      errorMessage: ""
    }
  },
  async beforeMount()
  {
    // Ensure the user is not already logged in
    if (await check_is_logged_in())
    {
      // User is logged in; showing the login page makes very little sense; redirect them to the page they were previously on
      alert("Already logged in!");
      this.$router.go(-1);
    }
  },
  methods: {
    handleLogin: async function ()
    {
      if (this.credentials.username === "" || this.credentials.password === "")
      {
        // Credentials not filled out! Set the error message, and the v-if bindings will show the user the field that they're missing.
        this.errorMessage = "Missing username or password";
        return;
      }

      // Attempt to perform the login
      var response = await perform_api_login(this.credentials.username, this.credentials.password)
      if (response)
      {
        // Successful login! Redirect to the home page
        this.$router.push("/");
      }
      else
      {
        // Unsuccessful login! Show the user an error
        this.errorMessage = "Incorrect username or password";
        return;
      }
    },
    handleInputChanged: function ()
    {
      // Hide the error message if the user changes the input in a meaningful way (that way re-submitting is clearer / if they hit the same issue upon submitting, it'll look different)
      // NOTE: This isn't particularly neat, but avoids keeping a state variable / some form of message map; this is really just a quick & dirty testing way
      if (!this.errorMessage)
      {
        // Skip over all of the error code handling if no error is set
      }
      else if (this.errorMessage == "Incorrect username or password")
      {
        // Always clear the error if the user changes the input
        this.errorMessage = "";
      }
      else if (this.errorMessage == "Missing username or password")
      {
        // Only clear the error message if the user has actually filled out both fields
        if (this.credentials.username && this.credentials.password)
        {
          this.errorMessage = "";
        }
      }
      else
      {
        // Unknown error message; don't do anything
        console.log("Unknown error message!");
      }
    }
  },
  watch: {
    'credentials.username': function() {
      this.handleInputChanged();
    },
    'credentials.password': function() {
      this.handleInputChanged();
    }
  }
}
</script>
