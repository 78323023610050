// This component will provide a table of signups (the array of which are passed in as the v-model), and provide some additional information as well as moderation options
// NOTE: We're not fetching the signups here as that would give us less flexibility/control + then we'd need to implement some fancy way of returning the data to the user; we can always change this down the line, but this should at least give us most of the styling/markup in a seperate file + avoid most of the bulk in the main file.
<template>
  <div class="w-full">
    <t-card>
      <template v-slot:header>
        <h4 class="uppercase text-green-500">
          <span class="ml-3">Signups</span><span class="ml-3" v-if="value.length>0">({{ value.length }} total)</span>
        </h4>
      </template>

      <t-modal show v-if="selected_signup" :value="selected_signup!=null" ref="teamDetailModal"
        body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
        footerClass="bg-gray-400 p-3 flex justify-between"
        @closed="onTeamDetailModalClosed">
          <!-- This div allows for some protection against selected_signup being null, while still allowing us to use modal.show(). We may potentially just want to get rid of that show code, and always show it with a v-if for selected_tournament, but this makes more sense/will likely be clearer while programming -->
          <!-- UPDATE: Now switched to avoid weird alignment issues -->
            <img class="object-contain h-24" :src="selected_signup.team.team_image" />
            <h1>{{ selected_signup.team.name }}</h1>

            <br />
            <h2>Players:</h2>
            <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
          <t-table
            :headers="['Player #', 'Username', 'RPs']"
            :data="selected_signup.team.players"
          >
            <template v-slot:row="props">
              <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
                <td :class="props.tdClass">
                  <!-- Color the player's ID blue if they are the team's captain -->
                  <span :class="{'text-blue-500': selected_signup.team.captain.id == props.row.id, 'text-gray-500': selected_signup.team.captain.id != props.row.id }">
                  {{ props.row.id }}
                  </span>
                </td>
                <td :class="props.tdClass">
                  {{ props.row.username }}
                </td>
                <td :class="props.tdClass">
                  {{ props.row.rank }}
                </td>
              </tr>
            </template>
          </t-table>

          <template v-slot:footer>
              <t-button @click="removeSignup(selected_signup.signup_id)" variant="danger">
                <!-- TODO -->
                Remove Signup
              </t-button>
              <t-button @click="$refs.teamDetailModal.hide()" variant="primary">
                Close
              </t-button>	
            </template>
        </t-modal>

        <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
        <t-table
          :headers="['Signup #', 'Name', 'Captain', 'Number of Players', 'Actions']"
          :data="value"
        >
          <template v-slot:row="props">
            <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
              <td :class="props.tdClass">
                <!-- Color the signups green if the signup deadline has passed, and orange otherwise -->
                <span :class="{'text-orange-500': isInFuture(props.row.signup_deadline), 'text-green-500': !isInFuture(props.row.signup_deadline) }">
                {{ props.row.id }}
                </span>
              </td>
              <td :class="props.tdClass">
                {{ props.row.team.name }}
              </td>
              <td :class="props.tdClass">
                {{ props.row.team.captain.username }} ({{ props.row.team.captain.rank }}RP)
              </td>
              <td :class="props.tdClass">
                {{ props.row.team.players.length }}
              </td>
              <td :class="props.tdClass">
                <t-button size="sm" variant="secondary" @click="showTeamInfo(props.row)">View More</t-button>
                <t-button size="sm" variant="danger" @click="removeSignup(props.row.id)">Remove</t-button>
              </td>
            </tr>
          </template>
        </t-table>
    </t-card>
    </div>
</template>

<script>
import moment from 'moment';
import {apiClient} from "../apiClient";

export default {
  name: 'TournamentSignupManagement',
  data() {
    return {
      // Used for displaying more information about a team that has signed up
      selected_signup: null
    }
  },
  props: {
    value: {
      type: Array
    },
    tournament_id: {
      type: Number
    }
  },
  methods: {
    showTeamInfo: async function (signup)
    {
      // Get some more information about the team outside of the signup info (things like images/etc)
      var response = await apiClient.get('teams/'+signup.team.id).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to find tournament! Error: "+error);
          return false;
      })

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve that team's info; please try again later");
        return;
      }

      // Store the signup in the global "state" so we can access it from the tournament
      // NOTE: Include the signup ID for future reference + potential moderation actions within the modal; not something we're using now, but may become relevant in the future
      this.selected_signup = {
        signup_id: signup.id,
        team: response.data
      };

      // Show the modal - NOTE: This is now done by setting the selected_signup, but having this just in case probably won't harm(?), but also don't as the v-if will cause it to bug/error (and without it the properties, especially the ones in the template are "exposed"), and if the modal is closed the state will be set to null anyway
      //this.$refs.teamDetailModal.show();
    },
    onTeamDetailModalClosed()
    {
      // Modal closed - unset the selected signup!
      this.selected_signup = null
    },
    isInFuture(timestamp)
    {
      // moment() gives us the current timestamp, and the passed in timestamp will be in the future if the current time is before the passed in stamp
      return moment().isBefore(timestamp);
    },
    async removeSignup(signup_id)
    {
      // Execute the DELETE HTTP request that will remove the signup
      var response = await apiClient.delete('tournaments/manage/'+this.tournament_id+'/signups/'+signup_id).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to remove signup! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to remove signup! Please try again later");
        return;
      }

      // Signup deleted; close the modal (if it was open; if it wasn't this won't change anything) and refresh the tournament signups to reflect this change.
      // NOTE: Since we're not managing the fetching of tournament signups ourselves, we'll simply emit the refresh event and rely on the parent to refresh the list
      this.$emit('refresh');
      this.selected_signup = null;
    },
  }
}
</script>
