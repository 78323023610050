<template>
<!-- Based on https://tailwindcss.com/components/navigation/ -->
  <nav class="flex items-center justify-between flex-wrap bg-gray-500 p-6" v-if="shouldShowMenu">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <router-link to="/"><span class="font-semibold text-xl tracking-tight">TPL Admin</span></router-link>
    </div>
    
    <div class="block lg:hidden">
      <button class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
        <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
      </button>
    </div>
    
    <!-- TODO: This appears to throw the items-center stuff off-center? -->
    <div class="w-full block flex-grow lg:flex lg:items-left lg:w-auto">
      <div class="text-sm lg:flex-grow"></div>
      <div>
        <TimeZoneDropdown/>
      </div>
    </div>
  </nav>
</template>

<script>
import TimeZoneDropdown from "./TimeZoneDropdown";

export default {
  name: 'NavBar',
  components: {
    TimeZoneDropdown
  },
  computed: {
    shouldShowMenu() {
      console.log(this.$route.meta);
      // This function allows us to hide the menu on pages such as the login page
      // This may seem confusing at first, but we're inverting the noMenu value to easily catch defaults.
      // The meta property will default to false, so inverting it produces true: this means that the menu will show by default.
      // Now, if a page like the login page wants no menu, it sets noMenu to true, meaning it exists and carries the value of true,
      // which gets inverted to false meaning the menu doesn't show.
      return !this.$route.meta.noMenu;
    }
  }
}
</script>

<style scoped>
</style>
