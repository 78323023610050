<template>
  <t-select
    name="timezone-selector"
    v-model="selected_timezone"
    :options="timezone_options"
    @change="onTimezoneChanged"
  />
</template>

<script>
import moment from 'moment';

export default {
  name: 'TimeZoneDropdown',
  data() {
    return {
      timezone_options: [],
      selected_timezone: "UTC" // Default to UTC until we load the timezone from vuex
    }
  },
  beforeMount()
  {
    // Load the user's default timezone from vuex
    this.selected_timezone = this.$store.getters.timezone;
    // Load the timezone options
    this.getTimezoneOptions();
  },
  methods: {
    getTimezoneOptions: function ()
    {
      this.timezone_options = moment.tz.names();
    },
    onTimezoneChanged: function ()
    {
      console.log(this.selected_timezone);
      // Update the Vuex state to reflect this change in timezone
      this.$store.commit('changeTimezone', this.selected_timezone)
    }
  }
}
</script>
