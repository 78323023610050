<!-- This component will provide a table of matches (the array of which are passed in as the v-model), some additional information and the option to reset matches
      The current system does not have a bracket display, but this could be added in the future
      NOTE: We're not fetching the matches here as that would give us less flexibility/control + then we'd need to implement some fancy way of returning the data to the user; we can always change this down the line, but this should at least give us most of the styling/markup in a seperate file + avoid most of the bulk in the main file.-->
<template>
  <div class="w-full">
    <t-card>
      <template v-slot:header>
        <h4 class="uppercase text-green-500">
          <span class="ml-3">Matches</span> <span class="ml3">({{ value.length }} total)</span>
        </h4>
      </template>
      <t-modal show v-if="selected_match && !should_edit_match_outcome" :value="selected_match!=null" ref="matchDetailModal"
        body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
        footerClass="bg-gray-400 p-3 flex justify-between"
        @closed="onMatchDetailModalClosed">
        <!-- Show the "main" team's info if they received a bye -->
          <div class="flex mb-4">
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">#{{selected_match.id}} | Scheduled Match Time: {{ selected_match.time | formatTournamentTimestamp }}</div>
              </div>
          </div>

          <!-- Show a nice VS thingy with team images/all that if the match isn't a bye -->
          <div class="flex mb-4" v-if="!isBye(selected_match)">
            <div class="w-1/3 rounded overflow-hidden shadow-lg">
              <div class="px-6 py-4">
                  <div class="font-bold text-xl mb-2 text-orange-500">HOME TEAM</div>
              </div>
              <img class="object-contain w-full" :src="selected_match.team_a.team_image" />
              <div class="px-6 py-4">
                <!-- Highlight the team's name if it is the match's winner -->
                <div class="font-bold text-xl mb-2" :class="[(hasWinner(selected_match) && selected_match.team_a.id == selected_match.winner.id) ? 'text-green-500': '']">{{ selected_match.team_a.name }}</div>
              </div>
              <div class="px-6 py-4" v-if="hasWinner(selected_match)">
                <!-- Highlight the team's name if it is the match's winner -->
                <div class="font-bold text-xl mb-2">{{ selected_match.goals_a }} Goals</div>
              </div>
            </div>

            <!-- Centred VS -->
            <div class="w-1/3 font-bold mb-auto mt-auto text-xl mb-2">VS</div>
            
            <!-- Only show info about the second team if it is provided; show a TO BE DETERMINED otherwise -->
            <div class="w-1/3 rounded overflow-hidden shadow-lg" v-if="selected_match.team_b">
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2 text-blue-500">AWAY TEAM</div>
              </div>
              <img class="object-contain w-full" :src="selected_match.team_b.team_image" />
              <div class="px-6 py-4">
                <!-- Highlight the team's name if it is the match's winner -->
                <div class="font-bold text-xl mb-2" :class="[(hasWinner(selected_match) && selected_match.team_b.id == selected_match.winner.id) ? 'text-green-500': '']">{{ selected_match.team_b.name}}</div>
              </div>
              <div class="px-6 py-4" v-if="hasWinner(selected_match)">
                <!-- Highlight the team's name if it is the match's winner -->
                <div class="font-bold text-xl mb-2">{{ selected_match.goals_b }} Goals</div>
              </div>
            </div>
            <div class="w-1/3 rounded overflow-hidden shadow-lg" v-else>
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2 text-blue-500">AWAY TEAM</div>
              </div>
              <!-- Show the default team image; may want to replace with a question mark in the future -->
              <img class="object-contain w-full" src="https://theproleagues-media.s3.amazonaws.com/teams/default.png" />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2 text-orange-500">TO BE DETERMINED</div>
              </div>
            </div>
          </div>

          <!-- Show the "main" team's info if they received a bye -->
          <div class="flex mb-4" v-else>
            <div class="w-full rounded overflow-hidden shadow-lg">
              <img class="object-contain w-full" :src="selected_match.team_a.team_image" />
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">{{ selected_match.team_a.name }}</div>
              </div>
              <i>(This team has received a bye for this round)</i>
            </div>
          </div>

            <br />
            <div v-if="selected_match.team_a">
              <h2>Home Team Players:</h2>
              <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
            <t-table
              :headers="['Player #', 'Username', 'RPs']"
              :data="selected_match.team_a.players"
            >
              <template v-slot:row="props">
                <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
                  <td :class="props.tdClass">
                    <!-- Color the player's ID blue if they are the team's captain -->
                    <span :class="{'text-blue-500': selected_match.team_a.captain.id == props.row.id, 'text-gray-500': selected_match.team_a.captain.id != props.row.id }">
                    {{ props.row.id }}
                    </span>
                  </td>
                  <td :class="props.tdClass">
                    {{ props.row.username }}
                  </td>
                  <td :class="props.tdClass">
                    {{ props.row.rank }}
                  </td>
                </tr>
              </template>
            </t-table>
          </div>

          <div v-if="selected_match.team_b">
              <br />
              <h2>Away Team Players:</h2>
              <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
            <t-table
              :headers="['Player #', 'Username', 'RPs']"
              :data="selected_match.team_b.players"
            >
              <template v-slot:row="props">
                <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']">
                  <td :class="props.tdClass">
                    <!-- Color the player's ID blue if they are the team's captain -->
                    <span :class="{'text-blue-500': selected_match.team_b.captain.id == props.row.id, 'text-gray-500': selected_match.team_b.captain.id != props.row.id }">
                    {{ props.row.id }}
                    </span>
                  </td>
                  <td :class="props.tdClass">
                    {{ props.row.username }}
                  </td>
                  <td :class="props.tdClass">
                    {{ props.row.rank }}
                  </td>
                </tr>
              </template>
            </t-table>
          </div>

          <template v-slot:footer>
              <t-button v-if="!isBye(selected_match) && hasWinner(selected_match)" @click="resetMatch(selected_match.id)" variant="danger">
                <!-- TODO only if winner -->
                Reset Match Outcome
              </t-button>
              <t-button v-if="!isBye(selected_match)" @click="should_edit_match_outcome = true" variant="danger">
                Edit Match Outcome
              </t-button>
              <t-button @click="$refs.matchDetailModal.hide()" variant="primary">
                Close
              </t-button>	
            </template>
        </t-modal>
        <t-modal show v-if="selected_match && should_edit_match_outcome" :value="selected_match!=null" ref="matchOutcomeModal"
        body-class="text-xl flex flex-col items-center justify-center p-6 flex-grow"
        footerClass="bg-gray-400 p-3 flex justify-between"
        @closed="onMatchOutcomeModalClosed"
        hideCloseButton="true">
        <!-- Show the "main" team's info if they received a bye -->
          <div class="flex mb-4">
              <div class="px-6 py-4">
                <div class="font-bold text-xl mb-2">#{{selected_match.id}} | Scheduled Match Time: {{ selected_match.time | formatTournamentTimestamp }}</div>
              </div>
          </div>

          <div>
            <form>
              <div class="space-y-4">
                <div class="w-full">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold" for="region">
                    {{ selected_match.team_a.name }} Points
                  </label>
                  <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      v-model="selected_match.goals_a"
                      name="goals_a"
                      id="goals_a"
                      placeholder="goals_a"
                    />
                </div>
                <div class="w-full">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold" for="region">
                    {{ selected_match.team_b.name }} Points
                  </label>
                  <t-input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  v-model="selected_match.goals_b"
                  name="goals_b"
                  id="goals_b"
                  placeholder="goals_b"
                />
                </div>
                
                <div class="w-full">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold" for="region">
                    Winner
                  </label>
                  <t-select
                    v-model="selected_match.winner"
                    name="winner"
                    id="winner"
                    :options="[{value: selected_match.team_a, text: selected_match.team_a.name}, {value: selected_match.team_b, text: selected_match.team_b.name}]"
                  />
                </div>
                

              <div>
                <t-button @click="updateOutcome(selected_match.id)" variant="primary">
                  Submit
                </t-button>
              </div>
            </div>
            </form>
          </div>

          <template v-slot:footer>
              <t-button @click="onMatchOutcomeModalClosed" variant="danger">
                Close
              </t-button>	
            </template>
        </t-modal>

        <!-- See also: https://vue-tailwind.com/components/table.html#slot-row -->
        <t-table
          :headers="['Match #', 'Round', 'Home Team', 'Away Team', 'Match Time', 'Actions']"
          :data="value"
        >
          <template v-slot:row="props">
            <!-- Alternating colour, and mark byes with yet another class (assume byes only happen in the first round and are characterized by no opponent) -->
            <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '', isBye(props.row) ? 'bg-green-100': '']">
              <td :class="props.tdClass">
                <span :class="{'text-orange-500': props.row.winner==null, 'text-green-500': props.row.winner!=null }">
                {{ props.row.id }}
                </span>
              </td>

              <td :class="props.tdClass">
                {{ props.row.round_num }}
              </td>

              <td v-if="props.row.team_a" :class="props.tdClass">
                <!-- Mark this team's name in green if they are the winner -->
                <span :class="props.row.winner && props.row.team_a.id == props.row.winner.id ? 'text-green-500' : ''">{{ props.row.team_a.name }} ({{ props.row.team_a.players.length }} players)</span>
              </td>
              <td v-else :class="props.tdClass">
                <!-- Space filler to ensure other properties don't move to the wrong column -->
              </td>

              <td v-if="props.row.team_b" :class="props.tdClass">
                <!-- Mark this team's name in green if they are the winner -->
                <span :class="props.row.winner && props.row.team_b.id == props.row.winner.id ? 'text-green-500' : ''">{{ props.row.team_b.name }} ({{ props.row.team_b.players.length }} players)</span>
              </td>
              <td v-else :class="props.tdClass">
                <!-- Insert NO OPPONENT - BYE if this match counted as a bye / TO BE DETERMINED if we're still waiting for a preceding match -->
                <span v-if="isBye(props.row)" class="text-green-500">NO OPPONENT - BYE</span>
                <span v-else class="text-orange-500">TO BE DETERMINED</span>
              </td>

              <td v-if="!hasWinner(props.row)" :class="props.tdClass">
                <!-- Show the match's scheduled time if there is no winner yet/the match still has to be played -->
                <span>{{ props.row.time | formatTournamentTimestamp}}</span>
              </td>
              <td v-else :class="props.tdClass">
                <!-- Space filler to ensure other properties don't move to the wrong column -->
                <span class="text-orange-500">MATCH IS OVER</span>
              </td>
              
              <td :class="props.tdClass">
                <!-- NOTE: Some/most actions are only for "actual" matches that have already been played / not byes -->
                <!-- We are, however, keeping details open for byes to see more about a team -->
                <t-button size="sm" variant="secondary" @click="showMatchInfo(props.row)">View More</t-button>
                <t-button v-if="!isBye(props.row) && hasWinner(props.row)" size="sm" variant="danger" @click="resetMatch(props.row.id)">Reset</t-button>
              </td>
            </tr>
          </template>
        </t-table>

        <iframe
          :src="'https://www.theproleagues.com/tournaments/'+tournament_id+'/bracket_only'"
          class="w-full h-screen"
          frameborder="0">
          Match Bracket Loading...
        </iframe>

        <!-- NOTE: v-slot footer does not appear to work? -->
        <div class="mt-4">
          <t-button @click="generateBracket" variant="danger">Regenerate Bracket (WILL WIPE ALL MATCH DATA!)</t-button>
        </div>
          
    </t-card>
  </div>
</template>

<script>
import moment from 'moment';
import {apiClient} from "../apiClient";
import { store } from '@/store/index';

export default {
  name: 'TournamentMatchManagement',
  data() {
    return {
      // Used for displaying more information about a team that has signed up
      selected_match: null,
      should_edit_match_outcome: false
    }
  },
  props: {
    value: {
      type: Array
    },
    tournament_id: {
      type: Number
    }
  },
  mounted: function() {
    window.addEventListener('message', this.handleWindowMessage)
  },
  methods: {
    handleWindowMessage: function(event)
    {
      // Is this a bracket match selection event?
      if (event.data != null && event.data.type == 'match_selected' && event.origin == 'https://www.theproleagues.com')
      {
        // Yes! Show info for the selected match!
        console.log(this.value)
        for (var match_key in this.value)
        {
          var match = this.value[match_key];
          if (match.id == event.data.match_id)
          {
            // Found match - show its info to the user.
            this.showMatchInfo(match);
            break;
          }
        }
      }
    },
    showMatchInfo: async function (match)
    {
      // Get some more information about the teams that isn't included with the match response (these should exec in parallel)
      // Team a should always be included
      var team_a_promise = apiClient.get('teams/'+match.team_a.id).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to find tournament! Error: "+error);
          return false;
      })

      // Looks like JS supports awaiting null and will just return null
      var team_b_promise = null
      if (match.team_b && match.team_b.id)
      {
        team_b_promise  = apiClient.get('teams/'+match.team_b.id).catch(error => {
            // Catch all errors and fail the request if one is detected
            console.log("Unable to find tournament! Error: "+error);
            return false;
        })
      }

      // Await both requests
      var team_a_response = await team_a_promise;
      var team_b_response = await team_b_promise;

      // Ensure we got info about the relevant teams
      if (!team_a_response || (match.team_b && match.team_b.id && !team_b_response))
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to retrieve one of the team's detailed information; please try again later");
        return;
      }

      // Store the match in the global "state" so we can access it from the modal (the act of setting this will also open the modal)
      // We're creating our own custom "data structure" to hold the additional info about teams, so you'll have to add new properties as they come up
      this.selected_match = {
        id: match.id,
        round: match.round,
        round_num: match.round_num,
        team_a: team_a_response.data,
        team_b: team_b_response ? team_b_response.data : null, // Default to null if there isn't a second team
         // Kinda complex ternary set of operators below; this basically checks if there is a winner, and will get the detailed winning team info if there is one (or set it to null otherwise)
        winner: this.hasWinner(match) ? (match.team_a.id == match.winner.id ? team_a_response.data : team_b_response.data) : null,
        goals_a: match.goals_a,
        goals_b: match.goals_b,
        time: match.time
      };
    },
    onMatchDetailModalClosed()
    {
      // Modal closed - unset the selected match!
      this.selected_match = null
    },
    onMatchOutcomeModalClosed()
    {
      // Modal closed - unset the selected match!
      this.should_edit_match_outcome = false;
    },
    isInFuture(timestamp)
    {
      // moment() gives us the current timestamp, and the passed in timestamp will be in the future if the current time is before the passed in stamp
      return moment().isBefore(timestamp);
    },
    async resetMatch(match_id)
    {
      // Execute the POST HTTP request that will reset the match
      var response = await apiClient.post('tournaments/manage/'+this.tournament_id+'/matches/'+match_id+'/reset').catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to reset match! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to reset match! Please try again later");
        return;
      }

      // match reset; close the modal (if it was open; if it wasn't this won't change anything) and refresh the tournament matches to reflect this change.
      // NOTE: Since we're not managing the fetching of tournament matches ourselves, we'll simply emit the refresh event and rely on the parent to refresh the list
      this.$emit('refresh');
      this.selected_match = null;
    },
    async generateBracket()
    {
      // This function will generate a bracket for the active tournament

      // Execute the POST HTTP request that will create the bracket
      var response = await apiClient.post('tournaments/manage/'+this.tournament_id+'/bracket').catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to create bracket! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to create bracket! Please try again later");
        return;
      }

      // Refresh the tournament matches to reflect this change.
      // NOTE: Since we're not managing the fetching of tournament matches ourselves, we'll simply emit the refresh event and rely on the parent to refresh the list
      this.$emit('refresh');
    },
    isBye(match)
    {
      // Returns whether or not the inputted match was generated as a bye
      // NOTE: This assumes byes only happen in the first round (as byes are padding to get to the nearest power of 32 -> there should never be more byes than half a round, else there'd simply be fewer rounds) and are characterized by no opponent
      // UPDATE: Now assumes that it is a bye if the player has no oppened and it the winner
      // NOTE: We're using match.winner, match.team_a and match.winner.id to ensure both objects are set + the ID isn't null
      return match.team_b === null && match.winner && match.team_a && match.winner.id && match.winner.id === match.team_a.id//match.round_num==1
    },
    hasWinner(match)
    {
      // Returns whether or not the passed in match has a winner as a quick/useful shortcut
      return match.winner && match.winner.id
    },
    async updateOutcome(match_id)
    {
      // Execute the POST HTTP request that will update the match
      var response = await apiClient.post('tournaments/manage/'+this.tournament_id+'/matches/'+match_id+'/outcome', {
        'goals_a': this.selected_match.goals_a,
        'goals_b': this.selected_match.goals_b,
        'winner_id': this.selected_match.winner.id
      }).catch(error => {
          // Catch all errors and fail the request if one is detected
          console.log("Unable to update match! Error: "+error);
          return false;
      });

      // Were any error detected?
      if (!response)
      {
        // Detected an error! Alert the user of this, but stay on the page as the rest of the tournament info was (likely) loaded successfully/can still be updated
        // TODO: All of these alerts will probably need to be replaced with something like a model, but this is an MVP with the basic code so a more artistically minded person can come in and make it all look & feel good.
        alert("Unable to update match! Please try again later");
        return;
      }

      // match reset; close the modal (if it was open; if it wasn't this won't change anything) and refresh the tournament matches to reflect this change.
      // NOTE: Since we're not managing the fetching of tournament matches ourselves, we'll simply emit the refresh event and rely on the parent to refresh the list
      this.$emit('refresh');
      this.selected_match = null;
    },
  },
  computed: {
  },
  filters: {
    formatTournamentTimestamp(value)
    {
      // NOTE: We're using a filter here and accessing the store so we can recompute the value every time the timezone is changed without adding any complex watchers/calling functions again: it's simple; if the vuex state changes, this function will be called again.  
      return moment(value).tz(store.getters.timezone).format('DD/MM/YYYY HH:mm z')
    }
  },
}
</script>
